import { useEffect } from "react";
import { useSetAtom } from "jotai";

import { SLACK_ISSUE_REPORT_ATOM } from "@sellernote/_shared/src/jotaiStates/slackIssueReport";

/**
 * 이슈 리포트에 사용되는 debugging data를 설정하는 hook
 */
export default function useSetDebuggingDataForIssueReport() {
  const setDebuggingData = useSetAtom(
    SLACK_ISSUE_REPORT_ATOM.DEBUGGING_DATA_ATOM
  );

  useEffect(() => {
    return () => {
      // 중요 - 사용하는 컴포넌트가 unmount 되면 값을 reset한다
      setDebuggingData({});
    };
  }, [setDebuggingData]);

  return {
    /** 이슈 리포트에 사용되는 debugging data를 설정 */
    setDebuggingDataForIssueReport: setDebuggingData,
  };
}
